import React from 'react'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import Share from '../components/share/sharing'
import {metadata} from '../../config'
import loadable from '@loadable/component'
import {
  adDisplayDelay,
  delayMultiplicationFactor,
  googleScriptLoadingDelay
} from '../utils/articleUtil'
import {orderBy as _orderBy} from 'lodash'
import {isMobile} from 'react-device-detect'
import Helmet from 'react-helmet'
import consts from '../constants/consts'
import Navigate from '../components/navigate'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered'
import EmbedYoutube from '../components/youtube-video/EmbedYoutube'

const ArticlePopularPosts = loadable(() => import("../components/popularPosts/articlePopular"));
const AuthorDetails = loadable(() => import("./authorDetails"));
const WhatNext = loadable(() => import("../components/whatNext"));

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.pageContext.article;
        this.url = `${metadata.url}${props.pageContext.url}`;
        this.canonicalUrl = `${metadata.url}${props.pageContext.canonicalUrl}`;
        this.categories = _orderBy(this.data.category, ['position'], ['asc']);
        this.subCategory = this.categories.find(item => item.treeParent !== null);
        this.longAdCount = 0;

        this.getRecommendedPosts();

        this.schemaArticle = {
            '@context': 'https://schema.org',
            '@type': this.data.json && this.data.json.faq ? 'FAQPage' : 'Article',
            author: {
                '@type': 'Person',
                name: this.data.author.name,
                url: `${metadata.url}/authors/${this.data.author.slug}`
            },
            "publisher": {
                "@type": "Organization",
                "name": metadata.defaultTitle,
                "logo": {
                    "@type": "ImageObject",
                    "url": `${metadata.url}/images/logos/logo.jpeg`
                }
            },
            datePublished: this.data.publishedAt,
            description: this.data.excerpt,
            headline: this.data.seo.title,
            url: this.url,
            name: this.data.seo.title,
            wordCount: this.props.pageContext.wordCount,
            articleSection: this.subCategory.title
        }
        if (this.data.seo.image) {
            this.schemaArticle.image = {
                '@type': 'ImageObject',
                url: this.data.seo.image.url,
            }
        }
        if (this.data.json && this.data.json.faq) {
            this.schemaArticle.mainEntity = [];
            this.data.json.faq.forEach(faq => {
                this.schemaArticle.mainEntity.push({
                  "@type": "Question",
                  "name": faq.title,
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": faq.text
                  }
                });
            })
        } else {
            this.schemaArticle.mainEntityOfPage = this.url;
        }
    }

    getRecommendedPosts() {
        this.recommendedPosts = [];
        this.popularPosts = [];

        const slugs = [];
        let posts = [];
        const relatedArticlesObject = this.props.pageContext.relatedArticles
        posts = posts
            .concat(relatedArticlesObject.tags10 || [])
            .concat(relatedArticlesObject.tags9 || [])
            .concat(relatedArticlesObject.tags8 || [])
            .concat(relatedArticlesObject.tags7 || [])
            .concat(relatedArticlesObject.tags6 || [])
            .concat(relatedArticlesObject.tags5 || [])
            .concat(relatedArticlesObject.tags4 || [])
            .concat(relatedArticlesObject.tags3 || [])
            .concat(relatedArticlesObject.tags2 || [])
            .concat(relatedArticlesObject.tags1 || [])
            .concat(relatedArticlesObject.sameCategory || [])
            .concat(relatedArticlesObject.random || []);

        for (let i = 0; i < posts.length && !isMobile; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.popularPosts.push(post);
                slugs.push(post.slug);
            }
            if (this.popularPosts.length === 5) {
                break;
            }
        }

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.recommendedPosts.push(post);
                slugs.push(post.slug);
            }
        }
    }

    replace_nth(s, f, r, n) {
        // From the given string s, replace f with r of nth occurrence
        return s.replace(RegExp("^(?:.*?" + f + "){" + n + "}"), x => x.replace(RegExp(f + "$"), r));
    };

    getContent() {
        let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '');
        content = content.replace(/ aria-level="1"/g, '');
        content = content.replace(/ style="font-weight: 400;"/g, '');
        content = content.replace(/ padding-left:40px/g, '');

        let contentArray = [];
        if (this.props.pageContext.pageNumber) {
            contentArray = this.replace_nth(content, "<h2>", "$pension$<h2>", this.props.pageContext.pageNumber).split("$pension$");
            contentArray = this.replace_nth(contentArray[1], "<h2>", "$pension$<h2>", 2).split("$pension$");
            content = contentArray[0];
        } else {
            contentArray = content.split("<h2>");
            content = contentArray[0];
        }

        content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, "data-src=\"https://www.youtube.com/embed");

        return content;
    }

    componentDidMount() {
        setTimeout(() => {
            window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function () {
                googletag.display("div-at_incontent1");
            });
            try {
                window.instgrm.Embeds.process();
            } catch (e) {

            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));

        const youtubeVideoDisplay = () => {
            const vidDefer = document.getElementsByTagName('iframe');
            for (let i = 0; i < vidDefer.length; i++) {
                if (vidDefer[i].getAttribute('data-src')) {
                    vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
                }
            }
        }

        if (googleScriptLoadingDelay() === consts.SCRIPT_LOADING_TIME_WAIT) {
            window.onload = () => {
                setTimeout(() => {
                    youtubeVideoDisplay();
                }, consts.SCRIPT_LOADING_TIME_WAIT * delayMultiplicationFactor(isMobile));
            };
        } else {
            setTimeout(() => {
                youtubeVideoDisplay();
            }, 1000 * delayMultiplicationFactor(isMobile));
        }
    }

    render() {
        return <Layout>
            <Helmet>
                {/*{!this.props.pageContext.pageNumber && <link rel="preload" as="image" href={this.data.cover.responsiveImage.src}
                      imagesrcset={this.data.cover.responsiveImage.webpSrcSet} imagesizes={this.data.cover.responsiveImage.sizes}/>}*/}
                <meta name="keywords" content={this.data.tags}/>
                <script type="application/ld+json">{JSON.stringify(this.schemaArticle)}</script>
            </Helmet>
            <Basic seo={this.data.seo}/>
            <Twitter seo={this.data.seo} url={this.url}/>
            <Facebook seo={this.data.seo} url={this.url}/>
            <main className="wrapper">
                <div className={"w-full lg:flex"}>
                    <article className="flex-1 flex-grow-3">
                        <CategoryTitleBordered className={"uppercase"}
                                               parentLink={`/${this.subCategory.treeParent.slug}`}
                                               parentTitle={this.subCategory.treeParent.title}
                                               link={`/${this.subCategory.treeParent.slug}/${this.subCategory.slug}`}
                                               title={this.subCategory.title}/>
                        <header className="font-arial">
                            {!this.props.pageContext.pageNumber && this.data.ptNewsVideo && <div className={"news-video mx-2 md:mx-auto"}>
                                <EmbedYoutube video={this.data.ptNewsVideo}/>
                            </div>}
                            <h1 className="text-2xl lg:text-5xl font-montserrat font-semibold mt-4 mb-0 leading-tight">{this.data.heading}</h1>
                            {!this.props.pageContext.pageNumber && <p className={"font-semibold text-base lg:text-xl font-arial leading-snug mb-0 mt-4"}>{this.data.excerpt}</p>}
                            {!this.props.pageContext.pageNumber && <div className="py-2 flex items-center">
                                {this.data.author.profilePhoto &&
                                <img className="rounded-full mr-2" alt={this.data.author.name} width={80} height={80}
                                     src={`${this.data.author.profilePhoto?.url}&w=80&h=80&fit=crop`}/>}
                                <div className="text-black text-opacity-50">
                                    <div>
                                        By <Navigate href={`/authors/${this.data.author.slug}`}
                                                     className={"text-denim"}>{this.data.author.name}</Navigate>
                                    </div>
                                    {/*<time>{formatDate(this.data.publishedAt)}</time>*/}
                                    <span>&nbsp;·&nbsp;{this.props.pageContext.readingTime} min read</span>
                                    {this.data.isSponsored && <span>&nbsp;·&nbsp;sponsored</span>}
                                </div>
                            </div>}
                            {isMobile && <Share/>}
                        </header>
                        <main doc-container="main" className={"relative"}>
                            <div id="article-content" dangerouslySetInnerHTML={{__html: this.getContent()}}/>
                            {!isMobile && <div className={"absolute"} style={{top: 0, left: '-65px'}}>
                              <Share/>
                            </div>}
                            {this.props.pageContext.totalPages && this.props.pageContext.pageNumber < this.props.pageContext.totalPages &&
                            <div>Read more on the next page</div>}
                        </main>
                        <div className="ad-container">
                            <div className="advertisement-text">Advertisement</div>
                            <div id="div-at_incontent1"></div>
                        </div>
                        {this.props.pageContext.totalPages && this.props.pageContext.pageNumber < this.props.pageContext.totalPages &&
                        <Navigate
                            className={'w-full inline-block p-2 text-center bg-denim font-bold text-white text-2xl rounded-sm mt-2'}
                            href={`/${this.props.pageContext.url}/${this.props.pageContext.pageNumber + 1}`}>Next
                            ></Navigate>}

                        <WhatNext recommendedPosts={this.recommendedPosts.splice(0, isMobile ? 2 : 4)}/>
                        <AuthorDetails author={this.data.author}
                                       deferImage={true}
                                       className={"w-4/5 mx-auto"}
                                       nameClass={"text-18 mr-2 text-black text-opacity-75"}
                                       bioClass={"text-black text-opacity-75 mt-2 mb-4 leading-relaxed font-light"}/>
                    </article>
                    {!isMobile && <ArticlePopularPosts articles={this.popularPosts} rightSideAffiliate={this.data.rightSideAffiliate}/>}
                </div>
            </main>
        </Layout>
    }
}

export default Article
